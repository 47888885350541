import React, { useState } from 'react';
import { useCurrentUser, Button, Table, useClient } from '@digi-tim-19/components';
import { Icon, Modal as ModalAnt, Tooltip } from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import { routes } from '../../config/routes';
import { useDimensions } from '@digi-tim-19/utils';
import styled from 'styled-components';

const Modal = styled(ModalAnt)`
  .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    background: #00508c;
    border-bottom: none;
    .ant-modal-title {
      color: #fff;
    }
  }
`;

export const ModalUserBeta: React.FC = () => {
  const { result: user } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(true);

  const mutationAcceptBeta = useClient('UserAcceptBeta');

  const handleAccept = (isBeta: boolean) => {
    mutationAcceptBeta
      .fetch({
        variables: {
          isBeta,
        },
      })
      .then(() => {
        if (isBeta) {
          Modal.success({
            content: 'Parabéns! Você acaba de se tornar um novo usuário beta no Tá Na Rede 3.0.',
          });
        }
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  const allowUsers = new Set([
    'henrique.clementino@digi.ag',
    'thayse.sousa@conectrio.com.br',
    'bruno.souza@mc.com.br',
    'anaqueiroz@lojasfacell.com',
    'carlosandre_aragao@hotmail.com',
    'maria.nascimento@conectrio.com.br',
    'vaneska.sumak@hotmail.com',
    'luanaaraujo@lojasfacell.com',
    'carla.roberta@timmaceio.com',
    'correiatalita87@gmail.com',
    'geovanipinheiro@lojasfacell.com',
    'ghipattini@gmail.com',
    'contratoslimeiracentro@gmail.com',
    'guhribeiro2112@gmail.com',
    'ivaneidelsilva33@gmail.com',
    '123danielafernandes@gmail.com',
    'jeane.santos@nonistelecom.com.br',
    'camila.assis@alegriatelecom.com.br',
    'vanderleiasampaiorosa@gmail.com',
    'manupaiva50@gmail.com',
    'danikon18@gmail.com',
    'vanessavasquesviana@hotmail.com',
    'trsouza1936@gmail.com',
    'heloduarte1998@hotmail.com',
    'luanaoliveirapaula157@gmail.com',
    'macgomes@timbrasil.com.br',
    'ambarcellos@timbrasil.com.br',
    'redepositivabarra@gmail.com',
    'natanaeltimdatafor@hotmail.com',
    'eubratilieri@gmail.com',
    'cassilalopes20@gmail.com',
    'mariaizabelpahlribeiro00103@gmail.com',
    'bohmanncarol9@gmail.com',
    'claudiohames10@gmail.com',
    'ggrosa@timbrasil.com.br',
    'deiabuffon@hotmail.com',
    'nathalysalomaotimparanavai@gmail.com',
    'cati@lojasmohr.com.br',
    'andresiqueira.tim@gmail.com',
    'luannaguyss28@gmail.com',
    'jadersonmellotimshopark@gmail.com',
    'abelloni@timbrasil.com.br',
    'rafaelsalvino@timultrafibra.com.br',
    'gfgsilva@timbrasil.com.br',
    'rfcamargo@timbrasil.com.br',
    'masdaraujo@timbrasil.com.br',
    'pbpbezerra@timbrasil.com.br',
    'vmorgado@timbrasil.com.br',
    'crnascimento@timbrasil.com.br',
    'mzillman@timbrasil.com.br',
    'ffonseca_capgemini@timbrasil.com.br',
    'lojatimtockcelular@gmail.com',
    'leilamotainforcell.tim@gmail.com',
    'amandasilvabarros59@gmail.com',
    'mariaoliveira404@yahoo.com.br',
    'anekaroline047@gmail.com',
    'marleydantas20@gmail.com',
    'gabriela.cellphone@gmail.com',
    'werisondan@gmail.com',
    'viviane.bezerra0903@gmail.com',
    'jsantosjuniorsantos67@gmail.com',
  ]);

  const isVisible = new Set().has(user?.email!) && user?.isBeta !== true && user?.isBeta !== false && isOpen === true;

  return (
    <Modal
      visible={isVisible}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ cursor: 'pointer' }} onClick={() => setIsOpen(false)}>
            <Icon type="close-circle" />
          </div>
        </div>
      }
      footer={null}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <h4 style={{ fontWeight: 'bold' }}>Seja um Usuário Beta do Tá Na Rede 3.0!</h4>
        <div>
          Estamos trazendo uma nova experiência com o Tá Na Rede 3.0, e queremos que você faça parte dessa fase inicial!
          <br />
          Como usuário beta, você terá acesso antecipado às novidades e poderá ajudar a melhorar a plataforma com seu
          <br />
          feedback. Se deseja participar, basta clicar no botão <strong>"Aceito"</strong> abaixo. Após aceitar, uma nova
          opção será
          <br />
          adicionada ao menu, permitindo que você acesse diretamente o Tá Na Rede 3.0.
          <br /> <br />
          🚀 Clique em <strong>"Aceito"</strong> e venha testar essa novidade!
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px' }}>
          <Button key="submit" type="primary" onClick={() => handleAccept(false)}>
            Não aceito
          </Button>
          <Button key="submit" type="primary" onClick={() => handleAccept(true)}>
            Aceito
          </Button>
        </div>
      </div>
    </Modal>
  );
};
