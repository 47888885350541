"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var DataProviders_1 = require("./DataProviders");

var utils_1 = require("@digi-tim-19/utils");

var __1 = require("..");

exports.UserContextReact = React.createContext({
  result: null,
  resolved: false,
  loading: false,
  fetch: function noop() {},
  userIsInRole: function userIsInRole() {
    return false;
  },
  isAdmin: false,
  permissions: {}
});
var Provider = exports.UserContextReact.Provider;

exports.UserProvider = function (props) {
  var _a, _b;

  var lastLoggedUser = React.useRef(null);

  var _React$useContext = React.useContext(utils_1.SocketIOContext),
      socket = _React$useContext.socket;

  var userClientContext = DataProviders_1.useClient('User', {
    appendToFragment: "\n        _id\n        roleGroup {_id name channelName}\n        channels\n        region\n        avatarId\n        matricula\n        CPF\n        regionPath\n        viewOtherUsersContent\n        blockedCommenting\n        jobTitle {\n          name\n        }\n        avatar { signedUrl }\n        acceptedTerms {\n          term\n        }\n        notifications {\n          unreadIds,\n          readIds,\n        }\n        emailSecondary\n        gamefication {\n          nivel\n          chips\n        }\n        kpi {\n          jobTitle\n        }\n        isBeta\n      "
  });

  if (userClientContext.result) {
    lastLoggedUser.current = userClientContext.result;
  }

  var user = lastLoggedUser.current;
  React.useEffect(function () {
    var _a;

    if ((_a = user) === null || _a === void 0 ? void 0 : _a._id) {
      socket.emit('user-logged', user._id);
    }

    return function () {
      socket.off('user-logged');
    };
  }, [(_a = user) === null || _a === void 0 ? void 0 : _a._id]);
  React.useEffect(function () {
    utils_1.UserSession.listen(function (field) {
      console.log('field', field);
      if (field !== 'token') return;
      userClientContext.fetch();
    });
    userClientContext.fetch();
  }, []);

  var userIsInRole = function userIsInRole(oneOfRoles) {
    var _a;

    var userRoles = __1.verdade((_a = user) === null || _a === void 0 ? void 0 : _a.roles);

    if (!userRoles.length) {
      return false;
    }

    if (typeof oneOfRoles === 'string') {
      oneOfRoles = [oneOfRoles];
    }

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = oneOfRoles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var i = _step.value;
        if (userRoles.indexOf(i) !== -1) return true;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return false;
  };

  return React.createElement(Provider, {
    value: Object.assign(Object.assign({}, userClientContext), {
      result: user,
      userIsInRole: userIsInRole,
      isAdmin: userIsInRole('update_status_material') && ((_b = user) === null || _b === void 0 ? void 0 : _b.regionPath) === '_BR_',
      permissions: user ? props.parseUserPermissions(user) : {}
    })
  }, props.children);
};

function useCurrentUser() {
  return React.useContext(exports.UserContextReact);
}

exports.useCurrentUser = useCurrentUser;