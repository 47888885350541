import React, { useRef } from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { LoadingIndicator, ShareModalButton, useClient } from '@digi-tim-19/components';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useInformativeViewer } from './VisualizacaoInformativoHook';
import { clickDownload } from '@digi-tim-19/utils/build';
import { SwitchTemplate } from './SwitchTemplate';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { toPng } from 'html-to-image';
import { Icon } from 'antd';

const PageContent = styled.div`
  margin: auto;
`;

const Paragrafo = styled.p`
  margin-left: 10px;
  font-size: 30px;
  line-height: 37px;
  font-family: TimLight;
  margin-bottom: 20px;
  margin-top: 20px;
  color: rgb(0, 80, 140);
`;

const LinkAttacment = styled.a`
  margin-top: 10px;
  display: block;
  width: 100%;
`;

export const VisualizacaoInformativo = () => {
  const { id } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const apiAnalyticsDownload = useClient('AnalyticsDownloads');

  const { titleMaterial, templateName, templateProps, isLoading, downloadUrlAttachments, html } = useInformativeViewer(
    id || '',
  );

  if (isLoading) return <LoadingIndicator />;

  const downloadHtml = () => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${titleMaterial}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });

    apiAnalyticsDownload.fetch({ variables: { materialId: id } });
  };

  return (
    <PageTemplate>
      <React.Fragment>
        <PageContent>
          {templateProps ? (
            <React.Fragment>
              <Paragrafo>{titleMaterial}</Paragrafo>
              <TransformWrapper>
                <TransformComponent wrapperStyle={{ margin: 'auto' }}>
                  <div ref={ref}>
                    <SwitchTemplate templateName={templateName} templateProps={templateProps} html={html} />
                  </div>
                </TransformComponent>
              </TransformWrapper>
              {downloadUrlAttachments.length > 0 && (
                <>
                  {downloadUrlAttachments.map((item: any) => (
                    <LinkAttacment
                      key={item.fileName}
                      onClick={() => {
                        clickDownload(item.url, true);
                      }}
                    >
                      {item.fileName}
                    </LinkAttacment>
                  ))}
                </>
              )}
              <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                <a href="#" className="download" onClick={downloadHtml}>
                  <Icon type="download" />
                  Download
                </a>

                {id && (
                  <ShareModalButton
                    material={{
                      _id: id,
                    }}
                  />
                )}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
        </PageContent>
      </React.Fragment>
    </PageTemplate>
  );
};
