import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Banner } from './Banner';
import { CardsHome } from './CardsHome';
import { SubHeader, useClient } from '@digi-tim-19/components';
import { MainHeader } from '../../components/Layout/MainHeader';
import { MainMenu } from '../../components/Layout/MainMenu';
import { SiteFooter } from '../../components/Layout/SiteFooter';

import { siteImages } from '../../assets/images';
import { MainSocialFeed } from '../../components/Layout/MainSocialFeed';
import { useDimensions } from '@digi-tim-19/utils/build';

import { useCurrentUser, getRoutes } from '@digi-tim-19/components';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Modal } from 'antd';

const ContentHome = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;

export const Home = (props: THomeProps) => {
  const user = useCurrentUser();
  const history = useHistory();
  const routes = getRoutes();
  const [menuIsOpen, toggleMenu] = useState(false);
  const { isMobile } = useDimensions();
  const ReconheceUserBalance = useClient('ReconheceUserBalance', { fetchOnMount: true });

  useEffect(() => {
    user.fetch({ ignoreCached: true }).then((ctx) => {
      if (ctx.result?.channels?.includes('distribuicao')) {
        //caso seja do canal distribuição, redireciona para o extrato de pontos direto
        history.push(routes.informacoesExtrato.mount());
      } else {
        history.push(routes.home.mount());
      }
    });
  }, []);

  useEffect(() => {
    if (ReconheceUserBalance.result && user?.result?.disabledWithPoints) {
      const disabledAt = moment(user?.result?.disabledAt!);
      const shortageTime = moment(disabledAt).add(30, 'day');
      const diff = moment(shortageTime).diff(new Date(), 'day');
      const format = 'DD/MM/YYYY';

      Modal.error({
        title: 'Urgente!',
        content: `Seu acesso foi inativado em ${disabledAt.format(format)} e você possui ${
          ReconheceUserBalance.result.balance
        } pontos disponíveis para utilização até o ${shortageTime.format(format)}. Restam ${diff} dia${
          diff > 1 ? 's' : ''
        } para o uso dos pontos.`,
      });
    }
  }, [ReconheceUserBalance.result]);

  return (
    <React.Fragment>
      <SubHeader logoURL={siteImages.logoTim} />
      <MainHeader menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
      <MainMenu menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />

      <Banner />
      <ContentHome>
        {!isMobile && <MainSocialFeed />}
        <CardsHome />
      </ContentHome>
      <SiteFooter />
    </React.Fragment>
  );
};

export type THomeProps = {
  className?: string;
  children?: React.ReactNode;
};
