import React, { useState, useEffect, useMemo } from 'react';
import { Breadcrumb, TitleDescription, Table, Select, LoadingIndicator, useCurrentUser } from '@digi-tim-19/components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  NotificationsWrapper,
  FilterContainer,
  Button,
  CleanButton,
  SumWrapper,
  SumRow,
  SumValue,
} from './styleListar';
import { routes } from '../../../config/routes';
import { dateFormatList } from '../../../utils';
import moment from 'moment';
import { DatePicker, Modal } from 'antd';
import { useInvoiceOriginOptions } from '../../CampanhaIncentivo/useInvoiceOriginOptions';
import { formatDate, formatCurrency, clickDownload } from '@digi-tim-19/utils';
import { usePaginationClient } from './usePagination';
import { useClient } from '../../../autogenerated/client/client';
import { Button as ButtonAntd } from '@digi-tim-19/components';
const { RangePicker } = DatePicker;

const configPage = {
  pageName: 'Ordens de pagamento',
  icon: 'dollar',
  description: 'Listagem de ordens de pagamento.',
  messageDeletedSuccess: 'Ordem removida com sucesso',
  textButtonNew: 'CADASTRAR NOVA',
  routeNew: routes.ordemPagamentoCadastrar.mount(),
  routeView: routes.ordemPagamentoEditar,
  routeEdit: routes.ordemPagamentoEditar,
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciamento', link: '' },
    { title: 'Ordens de pagamento', link: '' },
    { title: 'Listar', link: '' },
  ],
  limit: 50,
};

const INVOICES_ORIGINS = ['tim', 'tim_coins_expirados', 'incentiveme'];

export const FaturaListar: React.FC<TListarFaturas> = () => {
  const user = useCurrentUser();

  const invoiceOrigins = useInvoiceOriginOptions();

  const invoiceOriginsFilter = useMemo(
    () => invoiceOrigins?.filter((origin) => new Set(INVOICES_ORIGINS).has(origin.value)),
    [invoiceOrigins],
  );

  const columns = useColumns();

  const client = usePaginationClient('WalletMovementPagination', {
    initial: {
      perPage: configPage.limit,
      page: 1,
    },
    itemsFragment: `
      op
      origin
      createdAt
      value
      channelName
    `,
    parseResult(result) {
      return result;
    },
  });

  const [objectSum, setObjectSum] = useState<any>({
    initialCreditTotal: 0,
    usedCreditTotal: 0,
    availableCreditTotal: 0,
  });

  const sumWallet = useClient('WalletMovementSum');

  useEffect(() => {
    sumWallet
      .fetch({
        variables: {
          invoiceOrigin: client.state.invoiceOrigin,
        },
      })
      .then((ctx) => {
        if (ctx && ctx.result) {
          setObjectSum({
            initialCreditTotal: ctx.result.initialCredit,
            usedCreditTotal: ctx.result.usedCredit,
            availableCreditTotal: ctx.result.availableCredit,
          });
        }
      });
  }, [client.state.invoiceOrigin]);

  const resetFilters = () => {
    client.updateFetch({
      invoiceOrigin: undefined,
      start: undefined,
      end: undefined,
    });
  };

  const handleDownload = async () => {
    clickDownload(client.downloadUrl);

    Modal.success({
      content: 'Download realizado com sucesso.',
    });
  };

  return (
    <PageTemplate>
      <>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo', link: routes.home.mount() },
              { title: 'Incentivo', link: '' },
              { title: 'Faturas', link: '' },
            ]}
          />
          <TitleDescription
            iconType={configPage.icon}
            title={configPage.pageName}
            description={configPage.description}
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Select
            placeholder="TIPO DE CARTEIRA"
            options={invoiceOriginsFilter}
            onChange={(value) => {
              client.updateFetch({ invoiceOrigin: value });
            }}
            value={client.state.invoiceOrigin}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={dateFormatList}
            placeholder={['PERÍODO', '']}
            onChange={(e: any) => {
              client.updateFetch({
                start: e[0] ? moment(e[0]).startOf('day')?.toISOString() : undefined,

                end: e[1] ? moment(e[1]).endOf('day')?.toISOString() : undefined,
              });
            }}
          />
          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
          <ButtonAntd onClick={() => handleDownload()}>Download Extrato</ButtonAntd>
        </FilterContainer>
        <SumWrapper>
          <SumRow>
            <SumValue>Valor total</SumValue>
            <SumValue>{formatCurrency(objectSum.initialCreditTotal)}</SumValue>
          </SumRow>
          <SumRow>
            <SumValue>Saldo usado total</SumValue>
            <SumValue>{formatCurrency(objectSum.usedCreditTotal)}</SumValue>
          </SumRow>
          <SumRow>
            <SumValue>Saldo disponível total</SumValue>
            <SumValue>{formatCurrency(objectSum.availableCreditTotal)}</SumValue>
          </SumRow>
        </SumWrapper>

        {client.loading ? (
          <LoadingIndicator />
        ) : (
          <Table columns={columns} dataSource={client.parsed} pagination={client.pagination} />
        )}
      </>
    </PageTemplate>
  );
};

export type TListarFaturas = {};

const useColumns = () => {
  return [
    {
      title: 'OPERAÇÃO',
      dataIndex: 'op',
      key: 'op',
      render: (text: string) => <p>{text === 'credit' ? 'Crédito' : 'Débito'}</p>,
    },
    {
      title: 'ORIGEM',
      dataIndex: 'origin',
      key: 'origin',
    },
    {
      title: 'DATA',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string) => <p>{formatDate(text, 'DD/MM/YYYY')}</p>,
    },
    {
      title: 'CANAL',
      dataIndex: 'channelName',
      key: 'channelName',
    },
    {
      title: 'VALOR',
      dataIndex: 'value',
      key: 'value',
      render: (text: string, obj: any) => (
        <p style={{ color: obj.op === 'credit' ? 'green' : 'red' }}>{`${obj.op === 'debit' ? '-' : ''}${formatCurrency(
          text,
        )}`}</p>
      ),
    },
  ];
};
