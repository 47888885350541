import React from 'react';
import { useHistory } from 'react-router';

import { Former, LoadingIndicator, useCurrentUser, verdade } from '@digi-tim-19/components';

import { EnumFileKind, EnumReconheceWalletStatus } from '../../autogenerated/client/types';

import { useClient } from '../../autogenerated/client/client';
import { routes } from '../../config/routes';
import { Divider, FormContainer } from './Cadastrar/styles';
import { useInvoiceOriginOptions } from '../CampanhaIncentivo/useInvoiceOriginOptions';
import { Alert, message } from 'antd';

const configPage = {
  kindCardImage: EnumFileKind.AnyImage,
  kindBannerContent: EnumFileKind.AnyImage,
  kindPresentation: EnumFileKind.Pdf,
  kindRegulation: EnumFileKind.Pdf,
  KindTable: EnumFileKind.Xlsx,
  routeList: routes.ordemPagamentoListar.mount(),
  messageSuccess: 'Ordem de pagamento cadastrada com sucesso',
  messageSuccessUpdate: 'Ordem de pagamento atualizada com sucesso',
  messageError: 'Ordem de pagamento não cadastrada',
};

export const Form = (props: TFormPaymentOrder) => {
  const history = useHistory();
  const isUpdate = !!props.initialValues;
  const user = useCurrentUser();
  const invoiceOrigins = useInvoiceOriginOptions();
  const ReconheceWalletCreateOne = useClient('ReconheceWalletCreateOne');
  const ReconheceWalletUpdateById = useClient('ReconheceWalletUpdateById');

  const [region, setRegion] = React.useState<string>('');
  const [channel, setChannel] = React.useState<string>('');

  const initValues = React.useMemo(
    () => ({
      ...props.initialValues,
      NFImages: props?.initialValues?.NFImageUrls?.find((el: any) => el)._id,
      POImages: props?.initialValues?.POImageUrls?.find((el: any) => el)._id,
    }),
    [props.initialValues],
  );

  const regions = useClient('RegionFindMany', {
    cache: true,
    fetchOnMount: true,
    appendToFragment: `items { _id, name }`,
  });

  const channels = useClient('ChannelFindMany', {
    cache: true,
    fetchOnMount: true,
    appendToFragment: `roleGroups { _id name }`,
  });

  const regionOptions = React.useMemo(() => {
    return buildOptions(regions.result?.filter((el) => !el?._id?.match(/HQ/)));
  }, [region, regions.result]);

  const channelOptions = React.useMemo(() => {
    return buildTreeOptions(channels.result);
  }, [channels.result]);

  const roleOptions = React.useMemo(() => {
    const options = buildOptions(channels.result?.find((el) => el?._id === channel)?.roleGroups);
    return options;
  }, [channels.result, channel]);

  if (user.loading) {
    return <LoadingIndicator />;
  }

  if (!user.userIsInRole('financeiro')) {
    return <h3>Sem premissão.</h3>;
  }

  return (
    <FormContainer>
      <Former
        initialValues={initValues}
        showLoadingState={ReconheceWalletCreateOne.loading}
        config={{
          fields: [
            {
              className: 'grid-row',
              inline: true,
              list: [
                {
                  name: 'initialCredit',
                  label: 'CRÉDITO',
                  type: 'number',
                  required: true,
                  maxWidth: '180px',
                },
                {
                  name: 'status',
                  label: 'Status',
                  type: 'select',
                  options: [
                    {
                      value: EnumReconheceWalletStatus.Draft,
                      label: 'Aguardando confirmação',
                    },
                    {
                      value: EnumReconheceWalletStatus.Available,
                      label: 'Confirmado',
                    },
                  ],
                },
                {
                  name: 'invoiceOrigin',
                  label: 'ORIGEM DA FATURA',
                  type: 'select',
                  options: invoiceOrigins,
                },
              ],
            },
            {
              custom: <Divider />,
            },
            {
              inline: true,
              list: [
                {
                  name: 'POIdentifier',
                  label: 'IDENTIFICAR DA ORDEM DE PAGAMENTO',
                  type: 'text',
                  required: true,
                },
                {
                  name: 'POImages',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: configPage.kindRegulation,
                    CTA: 'IMAGEM DA ORDEM DE PAGAMENTO',
                  },
                },
              ],
            },
            {
              inline: true,
              list: [
                {
                  name: 'NFIdentifier',
                  label: 'IDENTIFICADOR DA NOTA FISCAL',
                  type: 'text',
                  required: true,
                },
                {
                  name: 'NFImages',
                  label: '',
                  type: 'upload',
                  required: true,
                  extraProps: {
                    kind: configPage.kindPresentation,
                    CTA: 'IMAGEM DA NOTA FISCAL',
                  },
                },
              ],
            },
            {
              custom: <Divider />,
            },
            {
              inline: true,
              list: [
                {
                  name: 'region',
                  label: 'Regional',
                  options: [{ label: 'THQ', value: 'THQ' }, ...regionOptions],
                  required: true,
                  afterChange(value) {
                    setRegion(value);
                  },
                  extraProps: {
                    loading: regions.loading,
                  },
                },
                {
                  name: 'channel',
                  label: 'Canal',
                  treeOptions: channelOptions,
                  required: true,
                  afterChange(value) {
                    setChannel(value);
                  },
                  type: 'treeSelect',
                  extraProps: {
                    loading: channels.loading,
                  },
                },
                {
                  name: 'roleGroup',
                  label: 'Cargo',
                  options: roleOptions,
                  required: true,
                },
              ],
            },
          ],
          submitButton: {
            label: isUpdate ? 'Atualizar' : 'Salvar',
          },
          cancelButton: {
            label: 'CANCELAR',
            onClick: () => history.push(configPage.routeList),
          },
        }}
        onSubmit={async ({ data }: any) => {
          const record = {
            initialCredit: data.initialCredit,
            status: data.status,
            invoiceOrigin: data.invoiceOrigin,
            NFIdentifier: data.NFIdentifier,
            POIdentifier: data.POIdentifier,
            POImages: data.POImages,
            NFImages: data.NFImages,
            region: data.region,
            channel: data.channel,
            roleGroup: data.roleGroup,
          };

          if (!isUpdate) {
            ReconheceWalletCreateOne.fetch({
              variables: {
                record,
              },
            }).then((ctx) => {
              if (ctx.errors) {
                message.error(ctx.errors.join('\n'));
              } else {
                message.success(configPage.messageSuccess);
                history.push(configPage.routeList);
              }
            });
          } else {
            ReconheceWalletUpdateById.fetch({
              variables: {
                record: { ...record, _id: data._id },
              },
            }).then((ctx) => {
              if (ctx.errors) {
                message.error(ctx.errors.join('\n'));
              } else {
                message.success(configPage.messageSuccessUpdate);
                history.push(configPage.routeList);
              }
            });
          }
        }}
      />

      {!!ReconheceWalletCreateOne.error && (
        <Alert message="Erro" description={ReconheceWalletCreateOne.error} type="error" showIcon />
      )}
    </FormContainer>
  );
};

export type TFormPaymentOrder = {
  initialValues?: any;
};

function buildOptions(items?: null | any[]) {
  return verdade(items).map((el) => ({
    label: el.name || el._id,
    value: el._id,
  }));
}
function buildTreeOptions(items?: null | any[]) {
  return verdade(items).map((el) => ({
    id: el?._id,
    label: el.name || el._id,
    value: el._id,
    pId: el?.parentId ? el?.parentId : 0,
  }));
}
