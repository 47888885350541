import React, { useState, useEffect } from 'react';
import { useClient } from '../../../autogenerated/client/client';
import { TemplatesEmailItem } from '@digi-tim-19/components/build/main/TemplatesEmail/TemplatesEmailProps';
import { Email } from '../../../autogenerated/client/types';

export const useInformativeViewer = (id: string) => {
  const [titleMaterial, setTitleMaterial] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateProps, setTemplateProps] = useState<TemplatesEmailItem>();
  const [isLoading, setIsLoading] = useState(true);
  const [downloadUrlAttachments, setDownloadUrlAttachments] = useState<any>([]);
  const [html, setHTML] = useState('');

  const material = useClient('MaterialFindOne', {
    appendToFragment: `
      email{
        templateName
        templateProps
        attachments{
          _id
          url
          signedUrl
          downloadUrl
          title
        }
      }
      sentEmail {
        body
      }
    `,
    fetchOnMount: true,
  });

  const file = useClient('FileFindByIds');

  function updateTemplateProps(email: any, signedUrl: string) {
    setTemplateProps({
      defaultProps: {
        ...email?.templateProps,
        IMAGE_SRC_1: signedUrl,
      },
      headerLogo: '/defaultImages/template-header-consumer.png',
      footerLogo: '/defaultImages/template-footer-consumer.png',
      hideButton: !!email?.templateProps?.HIDE_BUTTON,
    });
    setIsLoading(false);
  }

  function findFile(email: Email, _ids: string[], IMAGE_SRC_1: string) {
    file
      .fetch({
        variables: {
          _ids: _ids,
          limit: 2,
        },
      })
      .then((ctxFile) => {
        if (ctxFile.result) {
          ctxFile.result.forEach((item) => {
            if (item?._id === IMAGE_SRC_1) {
              updateTemplateProps(email, item?.signedUrl || '');
            }
          });
        }
        setIsLoading(false);
      });
  }

  useEffect(() => {
    material.fetch({ variables: { filter: { _id: id } } }).then((ctx) => {
      const email = ctx.result?.email;

      setTitleMaterial(ctx.result?.title || '');
      setHTML(ctx.result?.sentEmail?.body || '');

      if (email?.templateName) {
        setTemplateName(email.templateName);

        const urlAttachment = email?.attachments?.map((item) => {
          return {
            url: item?.signedUrl,
            fileName: item?.title || '',
          };
        });

        if (!!urlAttachment) setDownloadUrlAttachments(urlAttachment);

        const { IMAGE_SRC_1 } = email?.templateProps;

        let _ids: any[] = [];

        if (IMAGE_SRC_1) {
          _ids = [IMAGE_SRC_1].filter((item) => item.indexOf('data') < 0 && item.indexOf('/static') < 0);
        }

        if (_ids.length > 0) {
          if (_ids[0].indexOf('https://') === 0) {
            updateTemplateProps(email, _ids[0]);
          } else {
            findFile(email, _ids, IMAGE_SRC_1);
          }
        } else {
          updateTemplateProps(email, '');
        }
      }
    });
  }, [id]);

  return {
    titleMaterial,
    templateName,
    templateProps,
    isLoading,
    downloadUrlAttachments,
    html,
  };
};
