import React from 'react';
import {
  Button,
  Former,
  SelecionarPublico,
  usePublico,
  SearchUser,
  LoadingIndicator,
  InputFieldConfig,
} from '@digi-tim-19/components';
import {
  CreateOneMaterialInput,
  EnumFileKind,
  EnumMaterialCommunicationItemsConfigKind,
  EnumMaterialKind,
  EnumMaterialStatus,
  Material,
  MaterialCommunicationItemsConfig,
} from '../../../autogenerated/client/types';
import moment from 'moment';
import { routes } from '../../../config/routes';
import { useHistory } from 'react-router';
import { Container } from '../Cadastrar/stylesCadastrar';
import { useClient } from '../../../autogenerated/client/client';
import { TemplateFields } from './TemplateFields';
import { message } from 'antd';
import { EmailProps } from '@digi-tim-19/components/build/main/TemplatesEmail/TemplatesEmailProps';
import { siteImages } from '../../../assets/images';
import { EnumEmailTemplateName } from '@digi-tim-19/components/build/main/DataProviders/types';
import { useDimensions, clickDownload } from '@digi-tim-19/utils/build';
import { useContentTypePermissions } from '../../../hooks/useContentTypePermissions';
import { useCategoriesSubCategoriesForm } from '../../../hooks/categories/useCategoriesSubCategoriesForm';
//import { selectedChannels } from '../../../utils/selectedChannels';
//const selectedChannelsConsumer = selectedChannels();

const DocKind = EnumFileKind.Pdf;

enum Mode {
  html,
  template,
}

export const FormInformativos = (props: TFormMaterialEditProps) => {
  const permissions = useContentTypePermissions();
  const { availableAtChannels, availableAtRegions, availableAtRoleGroups } = usePublico();
  const [formData, setFormData] = React.useState<any>({});
  const [mode, setMode] = React.useState<Mode>(Mode.template);

  const history = useHistory();
  const [downloadUrlAttachment, setDownloadUrlAttachment] = React.useState<any[]>();

  const apiAnalyticsDownload = useClient('AnalyticsDownloads');

  const categoriesFilter = [
    'menu:cardapio_de_ofertas',
    'menu:residencial',
    'menu:planos_corporativos',
    'menu:cardapio_smb',
    'menu:processos',
    'menu:tim_tech',
    'menu:jornada',
    'menu:rede',
    'menu:outros',
    'menu:veja_mais',
    'menu:campanha_de_incentivo',
  ];

  const {
    categories,
    subCategories,
    categoriesSelected,
    subCategoriesSelected,
    getCategoriesInitialValue,
    getSubCategoriesInitialValue,
    selectCategory,
    setSubCategoriesSelected,
  } = useCategoriesSubCategoriesForm(categoriesFilter, props.initialValues, true);

  const isUpdate = !!props.initialValues && !props.isDuplication;

  const initialValues = React.useMemo(() => {
    let email = props.initialValues?.email;

    const html = formData?.html || email?.templateProps?.html;

    if (email?.templateName === EnumEmailTemplateName.PlainHtml) {
      setMode(Mode.html);
    }

    if (formData.templateName === EnumEmailTemplateName.PlainHtml) {
      setMode(Mode.html);
    }

    const IMAGE_SRC_1 = email?.images?.IMAGE_SRC_1 || siteImages.bgTemplateEmail600x900;

    const IMAGE_SRC_2 = email?.images?.IMAGE_SRC_2 || siteImages.bgTemplateEmail640x300;

    const templateProps = {
      ...email?.templateProps,
      IMAGE_SRC_1,
      IMAGE_SRC_2,
      ...formData.templateProps,
    };

    const mailing = props.initialValues?.files?.find((item) => item?.kind === EnumFileKind.Mailing)?._id || '';

    let attachmentIds: any;

    if (email?.attachments && email?.attachments.length > 0) {
      attachmentIds = email?.attachments?.map((item) => item?._id);

      const fileItems = email?.attachments?.map((item) => {
        return {
          _id: item?._id,
          downloadUrl: item?.signedUrl,
          title: item?.title,
        };
      });

      setDownloadUrlAttachment(fileItems);
    }

    const initialCategories = (props.initialValues?.categories || []).filter(
      (item) => item?._id !== 'menu:informativos',
    );

    return {
      ...props.initialValues,
      ...formData,
      documento: (props.initialValues?.files || []).find((el) => el && el.kind === DocKind)?._id,
      categories: formData?.categories || getCategoriesInitialValue(initialCategories || []),
      subCategories: formData?.subCategories || getSubCategoriesInitialValue(initialCategories || []),
      templateName: formData?.templateName || email?.templateName,
      schedule: formData?.schedule || props.initialValues?.validity?.start,
      dateExpired: formData?.dateExpired || props.initialValues?.validity?.end,
      templateProps,
      html,
      notifyEmailsBeforeExpire:
        formData?.notifyEmailsBeforeExpire || props.initialValues?.validity?.notifyEmailsBeforeExpire,
      attachmentIds: formData?.attachmentIds || attachmentIds,
      mailing: formData?.mailing || mailing,
    };
  }, [formData, props.initialValues?.email]); //eslint-disable-line

  const downloadUrlMailing = props.initialValues?.files?.find((item) => item?.kind === EnumFileKind.Mailing)?.signedUrl;

  const createMaterial = useClient('MaterialCreateOne');
  const updateMaterial = useClient('MaterialUpdateOne');

  const { isMobile } = useDimensions();

  if (permissions.loading) return <LoadingIndicator />;

  return (
    <Container>
      <Former
        initialValues={initialValues}
        config={(renderProps) => {
          return {
            fields: [
              {
                inline: !isMobile,
                list: [
                  {
                    name: 'schedule',
                    label: 'Programar publicação',
                    type: 'datePicker',
                    required: true,
                    extraProps: {
                      showTime: true,
                      format: 'DD/MM/YYYY HH:mm:ss',
                    },
                    maxWidth: '250px',
                    enableDatePriorToCurrent: isUpdate,
                  },
                  {
                    name: 'dateExpired',
                    label: 'Data de expiração',
                    type: 'datePicker',
                    required: true,
                    extraProps: {
                      showTime: true,
                      format: 'DD/MM/YYYY HH:mm:ss',
                    },
                    maxWidth: '250px',
                  },

                  // {
                  //   name: 'category',
                  //   label: 'Categoria do Menu',
                  //   value: initialValues.category,
                  //   treeOptions: produtos,
                  //   type: 'treeSelect',
                  //   required: true
                  // }
                ],
              },

              {
                name: 'categories',
                label: 'Categorias',
                options: categories,
                required: true,
                extraProps: {
                  mode: 'tags',
                  onSelect: (value: string) => selectCategory(value, true),
                  onDeselect: (value: string) => selectCategory(value, false),
                },
              },

              {
                name: 'subCategories',
                label: 'Sub-Categorias',
                treeOptions: subCategories,
                defaultValue: subCategoriesSelected,
                type: 'treeSelect',
                afterChange: (value: string[]) => setSubCategoriesSelected(value),
                extraProps: {
                  treeCheckable: true,
                },
              },

              {
                list: [
                  {
                    name: 'title',
                    label: 'TÍTULO',
                    required: true,
                    validate: (value: any) => {
                      const format = /[#%&;]/;
                      if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                      if (value) return !(value.length > 110) ? undefined : 'Máximo de 110 caracteres';

                      return undefined;
                    },
                  },
                  {
                    name: 'description',
                    label: 'Breve descrição',
                    type: 'textarea',
                    validate: (value: any) => {
                      const format = /[#%&;]/;
                      if (format.test(value)) return 'Os caracteres #%&; não são permitidos';

                      if (value) return !(value.length > 1200) ? undefined : 'Máximo de 1200 caracteres';

                      return undefined;
                    },
                    extraProps: {
                      maxlength: 1200,
                      rows: 3,
                    },
                  },

                  mode === Mode.html && {
                    custom: {
                      render: () => (
                        <Button icon={'FileSearchOutlined'} onClick={() => setMode(Mode.template)} type={'primary'}>
                          Alterar para Template
                        </Button>
                      ),
                    },
                  },

                  mode === Mode.template && {
                    custom: {
                      render: () => (
                        <Button icon={'EditOutlined'} onClick={() => setMode(Mode.html)} type={'primary'}>
                          Alterar para Editor HTML
                        </Button>
                      ),
                    },
                  },

                  mode === Mode.html && {
                    name: 'html',
                    label: '',
                    type: 'texteditor',
                  },

                  mode === Mode.template && {
                    custom: {
                      render: () => <TemplateFields />,
                    },
                  },
                ],
              },
              {
                name: 'tags',
                type: 'tags',
                label: 'tags',
              },
              {
                name: 'notifyEmailsBeforeExpire',
                label: 'Notificar antes de expirar',
                placeholder: 'Notificar antes de expirar',
                extraProps: {
                  returnProp: 'email',
                },
                component: SearchUser,
              },
              {
                className: 'Footer',
                inline: !isMobile,
                list: [
                  {
                    custom: (
                      <SelecionarPublico
                        required={
                          !renderProps?.values?.availableForUsers || renderProps?.values.availableForUsers.length === 0
                        }
                        platform="consumer"
                        initialValues={props.initialValues}
                        //selectedChannels={!isUpdate ? selectedChannelsConsumer : []}
                        {...permissions.filterOptions}
                      />
                    ),
                  },
                  {
                    name: 'mailing',
                    label: '',
                    type: 'upload',
                    extraProps: {
                      kind: 'mailing',
                      CTA: 'IMPORTAR MAILING',
                      excludeCheckType: true,
                    },
                  },
                  !!downloadUrlMailing && {
                    custom: (
                      <a
                        onClick={() => {
                          clickDownload(downloadUrlMailing);
                        }}
                      >
                        Baixar mailing
                      </a>
                    ),
                  },
                ],
              },
              {
                list: [
                  {
                    name: 'attachmentIds',
                    label: '',
                    type: 'upload',
                    extraProps: {
                      kind: 'any_doc',
                      CTA: 'Anexar arquivo',
                      multiple: true,
                      listType: 'text',
                      onRemove: (file: any) => {
                        if (downloadUrlAttachment && downloadUrlAttachment.length > 0) {
                          const downloadUrlAttachmentFiltered = downloadUrlAttachment.filter(
                            (x: any) => x._id !== file.id,
                          );
                          setDownloadUrlAttachment(downloadUrlAttachmentFiltered);
                        }
                      },
                    },
                  },
                  !!downloadUrlAttachment && {
                    list: [
                      {
                        custom: downloadUrlAttachment.map((item: any) => {
                          return (
                            <a
                              className="download-attachment"
                              onClick={() => {
                                clickDownload(item.signedUrl);
                                apiAnalyticsDownload.fetch({ variables: { fileId: item?._id } });
                              }}
                            >
                              Baixar {item.title}
                            </a>
                          );
                        }),
                      },
                    ],
                  },
                ],
              },
              {
                name: 'availableForUsers',
                label: 'Responsável',
                component: SearchUser,
              },
            ],
            submitButton: { label: isUpdate ? 'Atualizar' : 'Cadastrar' },
            cancelButton: {
              label: 'CANCELAR',
              onClick: () => history.push(routes.informativosListar.path),
            },
          };
        }}
        onSubmit={async function ({ data }) {
          const templateProps: EmailProps = data.templateProps;
          const hasUsersSelected = Boolean(data?.availableForUsers && data?.availableForUsers?.length > 0);
          const isValidPublicSelected = Boolean(
            (availableAtRoleGroups.length > 0 && availableAtRegions.length > 0) ||
              (availableAtRoleGroups.length === 0 && availableAtRegions.length > 0),
          );

          let emailConfig: MaterialCommunicationItemsConfig;

          if (mode === Mode.html) {
            if (!data.html) {
              message.error('Html vázio.');
            }
            emailConfig = {
              kind: EnumMaterialCommunicationItemsConfigKind.Email,
              templateName: 'plainHTML',
              templateProps: { html: data.html },
              attachmentIds: data.attachmentIds === '' ? undefined : data.attachmentIds,
            };
          } else {
            if (data.templateName !== 'templateConsumer01') {
              let fieldsValidation = [
                { name: 'Link do botão', value: templateProps.BUTTON_LINK },
                { name: 'Texto do botão', value: templateProps.BUTTON_TITLE },
                { name: 'Descrição do template', value: templateProps.TEXT_1 },
                { name: 'Título do template', value: templateProps.TITLE_1 },
              ];

              if (data.templateName !== 'templateConsumer02')
                fieldsValidation.push({
                  name: 'Imagem',
                  value: templateProps.IMAGE_SRC_1,
                });

              const hasValidTemplateConfig = notifyEmptyFields(fieldsValidation);

              if (hasValidTemplateConfig) {
                const { html, ...cleared } = data.templateProps;
                emailConfig = {
                  kind: EnumMaterialCommunicationItemsConfigKind.Email,
                  templateName: data.templateName,
                  templateProps: cleared,
                  attachmentIds: data.attachmentIds === '' ? undefined : data.attachmentIds,
                };
              } else {
                return;
              }
            } else {
              const hasValidTemplateConfig = notifyEmptyFields([
                { name: 'Imagem 1', value: templateProps.IMAGE_SRC_1 },
                { name: 'Título do template', value: data.title },
              ]);

              if (hasValidTemplateConfig) {
                const { html, ...cleared } = data.templateProps;
                const hideButton =
                  cleared.HIDE_BUTTON === undefined || cleared.HIDE_BUTTON === null ? true : cleared.HIDE_BUTTON;

                emailConfig = {
                  kind: EnumMaterialCommunicationItemsConfigKind.Email,
                  templateName: data.templateName,
                  templateProps: {
                    ...cleared,
                    TEXT_1: ' ',
                    TITLE_1: data.title,
                    BUTTON_LINK: cleared.BUTTON_LINK || 'SEM LINK',
                    BUTTON_TITLE: cleared.BUTTON_TITLE || 'SEM BOTÃO',
                    HIDE_BUTTON: hideButton,
                  },
                  attachmentIds: data.attachmentIds === '' ? undefined : data.attachmentIds,
                };
              } else {
                return;
              }
            }
          }

          const isBefore = moment(data.dateExpired).isBefore(data.schedule);

          let record: CreateOneMaterialInput = {
            availableAtChannels: availableAtChannels || [],
            availableAtRegions: availableAtRegions || [],
            availableAtRoleGroups: availableAtRoleGroups || [],
            availableForUsers: data.availableForUsers,
            validity: {
              start: data.schedule,
              end: data.dateExpired,
              notifyEmailsBeforeExpire: data.notifyEmailsBeforeExpire,
            },
            kind: EnumMaterialKind.Communication,
            categories: categoriesSelected,
            title: data.title,
            description: data.description,
            fileIds: [data.mailing].filter(Boolean),
            tags: data?.tags?.filter(Boolean),
            status: EnumMaterialStatus.Approved,
            communicationItemsConfig: [emailConfig],
          };

          if (isBefore) {
            message.warning('A data de expiração não pode ser menor que a data de publicação.');
            console.log('data', data);
            setFormData(data);
          } else {
            const subCategories = data.subCategories || [];

            record.categories = ['menu:informativos', ...categoriesSelected, ...subCategories].filter(Boolean);

            if (!hasUsersSelected && !isValidPublicSelected) {
              message.error('Por favor, selecione um público ou usuários por matrícula');
              setFormData(data);
              return;
            }

            const callback = (ctx: { result?: any }) => {
              if (ctx?.result) {
                message.success(`Informativo ${isUpdate ? 'atualizado' : 'cadastrado'} com sucesso`);
                history.push(routes.informativosListar.mount());
              } else {
                setFormData(data);
                message.error(`Informativo não ${isUpdate ? 'atualizado' : 'criado'}`);
              }
            };

            if (isUpdate) {
              await updateMaterial
                .fetch({
                  variables: {
                    filter: { _id: props.initialValues!._id },
                    record,
                  },
                })
                .then(callback);
            } else {
              await createMaterial
                .fetch({
                  variables: {
                    record,
                  },
                })
                .then(callback);
            }
          }
        }}
      />
    </Container>
  );
};

// retorna false se um dos values for undefined e notifica um erro
function notifyEmptyFields(fields: { value: any; name: string }[]) {
  let isValid = true;

  Object.keys(fields).forEach((key) => {
    const item = (fields as any)[key];
    if (!item.value || item.value.indexOf('/static/media/bg-template') >= 0) {
      message.error(`Campo "${item.name}" em branco`);
      isValid = false;
    }
  });

  return isValid;
}

export type TFormMaterialEditProps = {
  initialValues?: Partial<Material>;
  isDuplication?: Boolean | undefined;
};
